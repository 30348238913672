<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header border-bottom">
            <div class="row d-flex align-items-center">
              <div class="col-6">
                <h5 class="mb-0">Edit Offer</h5>
              </div>
            </div>
          </div>
          <!--Card body-->
          <div class="card-body">
            <OffersInfo :offer-id="offerId" :class="activeClass" @update:handleSave="handleSave"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import formMixin from "@/mixins/formMixin.js";
import store from "@/store";
import {mapGetters} from "vuex";
import showSwal from "@/mixins/showSwal";
import OffersInfo from "@/views/offers/Info.vue";

export default {
  name: 'EditOffer',
  components: {
    OffersInfo,
  },
  data() {
    return {
      activeClass: "js-active position-relative",
      apiValidationErrors: {},
      editedOffer: {},
      metricTypeOptions: [
        'CPA',
        'CPL',
        'SS',
      ],
      privacyLevels: [
        'public',
        'private',
        'semiprivate',
      ],
      geoOptions: ['PL', 'RO', 'CZ', 'BG', 'DE']
    }
  },
  props: {
    offerId: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapGetters({
      productOptions: 'products/getProductNamesWithIdsData',
      offer: "offers/getOffer"
    }),
  },
  created() {
    store.dispatch("products/getProductNamesWithIds");
  },
  mixins: [formMixin],
  methods: {

    async handleSave(value) {
      this.resetApiValidation();
      this.editedOffer = value;

      try {
        await this.$store.dispatch("offers/editOffer", {
          id: this.editedOffer.id,
          price: this.editedOffer.price ? parseFloat(this.editedOffer.price) : 0,
          payout: parseFloat(this.editedOffer.payout),
          azurelab_commission: parseFloat(this.editedOffer.azurelab_commission) ?? 0,
          description: this.editedOffer.description,
          niche: this.editedOffer.niche,
          offer_type: this.editedOffer.offer_type,
          privacy_level: this.editedOffer.privacy_level,
          geo: this.editedOffer.geo,
          in_house: this.editedOffer.in_house === 1,
          metric_type: this.editedOffer.metric_type,
          product_id: this.editedOffer.product?.id.toString(),
          product: {
            type: "products",
            id: this.editedOffer.product?.id.toString()
          },
          relationshipNames: ['product']
        });

        if (this.editedOffer.bannerFileToUpload !== undefined) {
          console.log(this.editedOffer.bannerFileToUpload);
          await this.$store.dispatch("offers/addBannerFiles", this.editedOffer.bannerFileToUpload);
        }

        if (this.editedOffer.landingPageFileToUpload !== undefined) {
          await this.$store.dispatch("offers/addLandingPageFiles", this.editedOffer.landingPageFileToUpload);
        }

        showSwal.methods.showSwal({
          type: "success",
          message: "Offer edited successfully!",
          width: 500
        });
        await this.$router.push({name: "Offers List"})
      } catch (error) {

        if (error.response.data.errors) {
          this.setApiValidation(error.response.data.errors);
        }
        showSwal.methods.showSwal({
          type: "error",
          message: error.response.data.errors[0].detail,
          width: 500
        });
      }
    }
  }
}
</script>
