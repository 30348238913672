<template>
  <div class="py-4 container-fluid">
    <WebsDashboardFilters :update-filters="updateFilters" :offer-id="offerId"/>

    <div class="row">
      <div class="col">
        <webs-list :updated-filters="updatedFilters"/>
      </div>
    </div>

    <div
        v-if="dashboardStats"
    >
      <div class="row">
        <!-- Adjusted Mini Statistics Cards for better responsiveness -->
        <div class="col-12 col-md-4 col-lg-3 mt-sm-4 mini-stat-card-custom">
          <mini-statistics-card
              :title="{ text: 'Leads total', value: dashboardStats?.totalLeads }"
              detail=""
              :filters="updatedFilters"
              :icon="{
                name: 'leaderboard',
                color: 'text-white',
                background: 'success',
              }"
          />
        </div>
        <div class="col-12 col-md-4 col-lg-3 mt-sm-4 mini-stat-card-custom">
          <mini-statistics-card
              :title="{ text: 'Leads approved', value: dashboardStats?.approvedLeads }"
              detail=""
              :filters="updatedFilters"
              :leads-status="'approved'"
              :icon="{
                name: 'leaderboard',
                color: 'text-white',
                background: 'success',
              }"
          />
        </div>
        <div class="col-12 col-md-4 col-lg-3 mt-sm-4 mini-stat-card-custom">
          <mini-statistics-card
              :title="{ text: 'Leads Rejected/Trashed', value: dashboardStats?.rejectedLeads + '/' + dashboardStats?.trashedLeads }"
              detail=""
              :filters="updatedFilters"
              :leads-status="'rejected'"
              :icon="{
                name: 'leaderboard',
                color: 'text-white',
                background: 'success',
              }"
          />
        </div>
<!--        <div class="col-12 col-md-4 col-lg-3 mt-sm-4 mini-stat-card-custom">-->
<!--          <mini-statistics-card-->
<!--              :title="{ text: 'Leads trashed', value: dashboardStats?.trashedLeads }"-->
<!--              detail=""-->
<!--              :filters="updatedFilters"-->
<!--              :leads-status="'trashed'"-->
<!--              :icon="{-->
<!--                name: 'leaderboard',-->
<!--                color: 'text-white',-->
<!--                background: 'success',-->
<!--              }"-->
<!--          />-->
<!--        </div>-->
        <div class="col-12 col-md-4 col-lg-3 mt-sm-4 mini-stat-card-custom">
          <mini-statistics-card
              :title="{ text: 'Leads processing', value: dashboardStats?.onHoldLeads }"
              detail=""
              :filters="updatedFilters"
              :leads-status="'hold'"
              :icon="{
                name: 'leaderboard',
                color: 'text-white',
                background: 'success',
              }"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-4 col-lg-3 mt-sm-4 mini-stat-card-custom">
          <mini-statistics-card
              :title="{ text: 'AVG CPA', value: dashboardStats?.cpa }"
              :is-clickable="false"
              detail=""
              :icon="{
                name: 'attach_money',
                color: 'text-white',
                background: 'info',
              }"
          />
        </div>
<!--        <div class="col-12 col-md-4 col-lg-3 mt-sm-4 mini-stat-card-custom">-->
<!--          <mini-statistics-card-->
<!--              :title="{ text: 'Payout total', value: dashboardStats?.totalPayout }"-->
<!--              detail=""-->
<!--              :filters="updatedFilters"-->
<!--              :leads-status="'approved'"-->
<!--              :icon="{-->
<!--                name: 'leaderboard',-->
<!--                color: 'text-white',-->
<!--                background: 'info',-->
<!--              }"-->
<!--          />-->
<!--        </div>-->
        <div class="col-12 col-md-4 col-lg-3 mt-sm-4 mini-stat-card-custom">
          <mini-statistics-card
              :title="{ text: 'Total paid out', value: dashboardStats?.paidOut }"
              :is-clickable="false"
              detail=""
              :icon="{
                name: 'attach_money',
                color: 'text-white',
                background: 'info',
              }"
          />
        </div>
        <div class="col-12 col-md-4 col-lg-3 mt-sm-4 mini-stat-card-custom">
          <mini-statistics-card
              :title="{ text: 'Earnings', value: dashboardStats?.totalPayout }"
              :is-clickable="false"
              detail=""
              :icon="{
                name: 'attach_money',
                color: 'text-white',
                background: 'info',
              }"
          />
        </div>
        <div class="col-12 col-md-4 col-lg-3 mt-sm-4 mini-stat-card-custom">
          <mini-statistics-card
              :title="{ text: 'Requested to withdraw', value: dashboardStats?.totalRequestedToWithdraw }"
              :is-clickable="false"
              detail=""
              :icon="{
                name: 'attach_money',
                color: 'text-white',
                background: 'info',
              }"
          />
        </div>
        <div class="col-12 col-md-4 col-lg-3 mt-sm-4 mini-stat-card-custom">
          <mini-statistics-card
              :title="{ text: 'AVG AR w/ trash', value: `${dashboardStats?.approvedRate} %` }"
              :is-clickable="false"
              detail=""
              :icon="{
                name: 'percent',
                color: 'text-white',
                background: 'primary'
              }"
          />
        </div>
        <div class="col-12 col-md-4 col-lg-3 mt-sm-4 mini-stat-card-custom">
          <mini-statistics-card
              :title="{ text: 'AVG AR w/o trash', value: `${dashboardStats?.approvedRateWithoutTrash} %` }"
              :is-clickable="false"
              detail=""
              :icon="{
                name: 'percent',
                color: 'text-white',
                background: 'primary',
              }"
          />
        </div>
<!--        <div class="col-12 col-md-4 col-lg-3 mt-sm-4 mini-stat-card-custom">-->
<!--          <mini-statistics-card-->
<!--              :title="{ text: 'AVG DR', value: `${dashboardStats?.deliveryRate} %` }"-->
<!--              :is-clickable="false"-->
<!--              detail=""-->
<!--              :icon="{-->
<!--                name: 'percent',-->
<!--                color: 'text-white',-->
<!--                background: 'primary',-->
<!--              }"-->
<!--          />-->
<!--        </div>-->
      </div>
<!--      <div class="row">-->
<!--        <div class="col-12 col-md-4 col-lg-3 mt-sm-4 mini-stat-card-custom">-->
<!--          <mini-statistics-card-->
<!--              :title="{ text: 'AVG AR', value: `${dashboardStats?.approvedRate} %` }"-->
<!--              :is-clickable="false"-->
<!--              detail=""-->
<!--              :icon="{-->
<!--                name: 'percent',-->
<!--                color: 'text-white',-->
<!--                background: 'primary',-->
<!--              }"-->
<!--          />-->
<!--        </div>-->
<!--        <div class="col-12 col-md-4 col-lg-3 mt-sm-4 mini-stat-card-custom">-->
<!--          <mini-statistics-card-->
<!--              :title="{ text: 'AVG DR', value: `${dashboardStats?.deliveryRate} %` }"-->
<!--              :is-clickable="false"-->
<!--              detail=""-->
<!--              :icon="{-->
<!--                name: 'percent',-->
<!--                color: 'text-white',-->
<!--                background: 'primary',-->
<!--              }"-->
<!--          />-->
<!--        </div>-->
<!--      </div>-->
      <div class="row">
        <div class="col">
          <best-webs-list :updated-filter="updatedFilter"/>
        </div>
      </div>
      <div class="mt-sm-4">
        <leads-chart-card
            v-if="dashboardStats"
            v-model:dates-range="campaignCreatedAt"
            v-model:leads-stats="dashboardStats.datesWithLeads"
        />
      </div>
    </div>
  </div>
</template>

<script>
import US from "../../../assets/img/icons/flags/US.png";
import DE from "../../../assets/img/icons/flags/DE.png";
import GB from "../../../assets/img/icons/flags/GB.png";
import BR from "../../../assets/img/icons/flags/BR.png";
import booking1 from "../../../assets/img/products/product-1-min.jpg";
import booking2 from "../../../assets/img/products/product-2-min.jpg";
import booking3 from "../../../assets/img/products/product-3-min.jpg";
import MiniStatisticsCard from "@/views/template/dashboards/components/MiniStatisticsCard.vue";
import WebsList from "@/views/dashboards/WebsDashboard/WebsList.vue";
import LeadsChartCard from "@/views/dashboards/components/LeadsChartCard.vue";
import dinero from "dinero.js";
import store from "@/store";
import {mapGetters} from "vuex";
import BestWebsList from "@/views/dashboards/WebsDashboard/BestWebsList.vue";
import WebsDashboardFilters from "@/views/dashboards/WebsDashboard/WebsDashboardFilters.vue";
var filters = {}
export default {
  name: "DashboardDefault",
  components: {
    WebsDashboardFilters,
    BestWebsList,
    LeadsChartCard,
    WebsList: WebsList,
    MiniStatisticsCard
  },
  props: {
    offerId: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapGetters({
      // map `this.doneCount` to `this.$store.getters.doneTodosCount`
      dashboardStats: 'leads/getDashboardStats',
      productOptions: 'products/getProductNamesData',
      userOptions: 'users/getUsersIds',
    })
  },
  methods: {
    async updateFilters(updatedFilter) {
      if (undefined == updatedFilter) {
        return;
      }
      if ('delete' === updatedFilter.decision) {
        updatedFilter.filters.forEach(filter => { // I need to pass array of filters because DateFilter returns two filters: start and end date.
          delete filters[filter.name]
        })
      }

      if ('add' === updatedFilter.decision) {
        updatedFilter.filters.forEach(filter => {
          filters[filter.name] = filter.value
        })
      }

      this.updatedFilters = { ...filters };
    },
  },
  created() {
    store.dispatch("products/getProductNames");
    store.dispatch("users/getUsersIds");
  },
  data() {
    return {
      updatedFilters: {},
      paidOutValue: dinero({amount: 0, currency: 'USD'}),
      booking1,
      booking2,
      booking3,
      US,
      DE,
      GB,
      BR,
      searchGeo: '',
      searchOfferType: '',
      searchUser: '',
      searchProduct: '',
      offerTypeOptions: [
        {name: 'Full price', value: 'full_price'},
        {name: 'Sample', value: 'sample'},
      ],
      geoOptions: [
        'PL', 'RO', 'CZ', 'BG', 'DE'
      ],
      campaignCreatedAt: {},
      leadsCreatedAt: {},
    };
  },
};
</script>
