<!--TODO change this list depending on the role -->
<template>
  <div id="sidenav-collapse-main"
       class="w-auto h-auto collapse navbar-collapse max-height-vh-100 h-100 scrollable-list">
    <ul class="navbar-nav">
      <div v-if="role === 'affiliate'">
        <li class="nav-item pe-0">
          <AffiliateManagerInfo/>
        </li>
        <hr class="horizontal light mt-2 mb-2"/>
      </div>

      <li :class="getRoute() === 'dashboards' ? 'active' : ''" class="nav-item">
        <sidenav-collapse :class="getRoute() === 'brooklynAlice' ? 'active' : ''" :nav-text="name"
                          collapse-ref="brooklynAlice">
          <template #icon>
            <img v-if="getImage" :src="getImage" class="avatar"/>
          </template>
          <template #list>
            <ul class="nav pe-0">
              <!-- nav links -->
              <!--              <sidenav-item :to="{ name: 'User Profile' }" mini-icon="P" text="My Profile" />-->
              <sidenav-item :to="{ name: 'User Profile' }" mini-icon="S" text="My Profile"/>
              <sidenav-item :to="{ name: 'Login' }" mini-icon="L" text="Logout" @click="logout"/>
            </ul>
          </template>
        </sidenav-collapse>
      </li>

      <template>
        <ul class="nav pe-0">
          <!-- nav links -->
          <!--              <sidenav-item :to="{ name: 'User Profile' }" mini-icon="P" text="My Profile" />-->
          <sidenav-item :to="{ name: 'User Profile' }" mini-icon="S" text="My Profile"/>
          <sidenav-item :to="{ name: 'Login' }" mini-icon="L" text="Logout" @click="logout"/>
        </ul>
      </template>

      <hr class="horizontal light mt-2 mb-2"/>


      <div v-if="role">
        <sidenav-item v-if="role !== 'affiliate' && role !== 'advertiser'" :icon="['fas', 'house']"
                      :to="{ name: 'Webs Dashboard' }"
                      mini-icon="L"
                      text="Dashboard"/>
        <sidenav-item v-if="role === 'affiliate' || role === 'advertiser'" :icon="['fas', 'house']"
                      :to="{ name: 'Web Campaigns Dashboard', params: {webId: webId} }"
                      mini-icon="L" text="Dashboard"/>
        <sidenav-item v-if="role !== 'affiliate' && role !== 'advertiser'" :icon="['fas', 'user']"
                      :to="{ name: 'Users List' }"
                      mini-icon="L" text="Users"/>
        <sidenav-item :icon="['fas', 'briefcase']" :to="{ name: 'Offers List' }" mini-icon="L" text="Offers"/>
        <sidenav-item :icon="['fas', 'route']" :to="{ name: 'Campaigns List' }" mini-icon="L" text="Campaigns"/>
        <sidenav-item v-if="role === 'admin' || role === 'affiliate_manager'" :icon="['fas', 'user']"
                      :to="{ name: 'Custom Payout List' }" mini-icon="L" text="Custom Payouts"/>
        <sidenav-item :icon="['fas', 'dollar-sign']" :to="{ name: 'Withdrawals List' }" mini-icon="L" text="Finance"/>
        <sidenav-item :icon="['fas', 'bookmark']" :to="{ name: 'Leads List' }" mini-icon="L" text="Leads"/>
        <sidenav-item :icon="['fas', 'life-ring']" :to="{ name: 'FAQ' }" mini-icon="L" text="FAQ"/>
      </div>
    </ul>
  </div>
</template>
<script>
import SidenavItem from "./SidenavItem.vue";
import SidenavCollapse from "./SidenavCollapse.vue";
import {mapState} from "vuex";
import AffiliateManagerInfo from "@/components/AffiliateManagerInfo.vue";

export default {
  name: "SidenavList",
  components: {
    AffiliateManagerInfo,
    SidenavItem,
    SidenavCollapse,
  },
  data() {
    return {
      title: "Soft UI WebsDashboard PRO",
      controls: "dashboardsExamples",
      isActive: "active",
      profile_image: null,
    };
  },
  computed: {
    ...mapState(["isRTL"]),
    getImage() {
      if (!this.profile_image) return null;
      else {
        return this.profile_image;
      }
    },
    webId() {
      const user = this.$store.getters["profile/getUserProfile"];
      return user?.ext_id;
    },
    role() {
      const user = this.$store.getters["profile/getUserProfile"];
      return user?.roles[0]?.name;
    },
    name() {
      const user = this.$store.getters["profile/getUserProfile"];
      return user?.first_name ? user?.first_name?.toString() : '' + " " + user?.last_name ? user?.last_name?.toString() : '';
    },
  },
  async created() {
    try {
      await this.$store.dispatch("profile/getProfile");
      this.profile_image = this.$store.getters["profile/getUserProfileImage"];
    } catch (error) {
      try {
        this.$store.dispatch("auth/logout");
      } finally {
        this.$router.push({name: "Login"});
      }
    }
  },

  methods: {
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    },
    logout() {
      this.$store.dispatch("auth/logout");
    },
  },
};
</script>

<style>
.scrollable-list {
  max-height: fit-content;
  overflow-y: auto;
  scrollbar-width: none;
}

.scrollable-list::-webkit-scrollbar {
  width: 0;
}
</style>
