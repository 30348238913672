<template>
  <div class="multisteps-form__panel border-radius-xl bg-white" data-animation="FadeIn">

    <div class="text-end">
      <material-button class="float-right btn btm-sm" @click.prevent="$router.push({ name: 'Offers List' })">
        Back to list
      </material-button>
    </div>
    <Form role="form" class="text-start mt-3" :validation-schema="schema">

      <div class="row mt-5 col-4">
        <VueMultiselect
            v-model="offer.product"
            placeholder="Product*"
            :options="productOptions ?? []"
            :show-no-results="true"
            :track-by="id"
            label="name"
            select-label='+'
            deselect-label="-"
            :searchable="true">
        </VueMultiselect>
      </div>

      <div class="row mt-5 col-4">
        <VueMultiselect
            v-model="offer.geo"
            placeholder="Geo*"
            :options="geoOptions"
            select-label='+'
            deselect-label="-"
        >
        </VueMultiselect>
      </div>

      <div class="row mt-5 col-4">
        <VueMultiselect
            v-model="offer.privacy_level"
            placeholder="Privacy level*"
            :options="privacyLevels ?? []"
            :show-no-results="true"
            select-label='+'
            deselect-label="-"
            :searchable="true">
        </VueMultiselect>
      </div>

      <div class="row mt-5 col-4">
        <VueMultiselect
            v-model="offer.offer_type"
            placeholder="Offer type*"
            :options="offerTypes ?? []"
            :show-no-results="true"
            select-label='+'
            deselect-label="-"
            :searchable="true">
        </VueMultiselect>
      </div>

      <div class="mt-5 row">
        <div class="mt-5 row  col-4">
          <edit-attachments :offer-id="offerId" :files="bannerFiles" title='Banners'/>
        </div>
        <FileUploadComponent
            :id="'bannerFile'" :text="'Add banner files'"
            @update:update-file="updateBannerFiles"/>

        <div class="mt-5 row col-4">
          <edit-attachments :offer-id="offerId" :files="landingPageFiles" title='Landing Page Files'/>
        </div>
        <FileUploadComponent
            :id="'landingPageFile'" :text="'Add landing page files'"
            @update:update-file="updateLandingPageFiles"/>
      </div>
      {{offer.in_house}}
      <div class="row mt-5">
        <div class="col-4">
          <material-switch id="in_house" v-model="offer.in_house" :checked="offer.in_house === 1" name="In house offer">In house offer</material-switch>
        </div>
      </div>
      <div v-if="undefined === offer.in_house || false === offer.in_house" class="row mt-5">
          <div class="col-4">
            <material-input
                id="azurelab_commission" v-model:value="offer.azurelab_commission" type="number" label="Azurelab commission (in $)"
                variant="static"
                name="azurelab_commission"></material-input>
            <validation-error :errors="apiValidationErrors.azurelab_commission"></validation-error>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-4">
          <material-input
              id="niche" v-model:value="offer.niche" :is-required="true" label="Niche"
              variant="static"
              name="niche"></material-input>
          <validation-error :errors="apiValidationErrors.niche"></validation-error>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col-4">
          <material-input
              id="price" v-model:value="offer.price" :is-required="true" type="number" label="Price (in local currency)" :placeholder="pricePlaceholder" variant="static"
              :disabled=priceDisabled
              name="price"></material-input>
          <validation-error :errors="apiValidationErrors.price"></validation-error>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col-4">
          <material-input
              id="payout" v-model:value="offer.payout" :is-required="true" type="number" label="Payout (in $)"
              variant="static"
              name="payout"></material-input>
          <validation-error :errors="apiValidationErrors.payout"></validation-error>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col-4">
          <material-input
              id="description" v-model:value="offer.description" :is-required="true" label="Description"
              name="description" placeholder="Working hours of call center and restrictions"
              variant="static"></material-input>
          <validation-error :errors="apiValidationErrors.description"></validation-error>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col-4">
          <VueMultiselect
              v-model="offer.metric_type"
              placeholder="Metric type*"
              :options="metricTypeOptions"
              select-label='+'
              deselect-label="-"
          >
          </VueMultiselect>
        </div>
      </div>

      <div class="button-row d-flex mt-4">
        <material-button
            type="button" color="dark" variant="gradient" class="ms-auto mb-0 js-btn-next"
            @click="handleSave">Save
        </material-button>
      </div>
    </Form>
  </div>
</template>
<script>
import MaterialButton from "@/components/MaterialButton.vue";
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/formMixin.js";
import {mapGetters} from "vuex";
import MaterialInput from "@/components/MaterialInput.vue";
import store from "@/store";
import EditAttachments from "@/views/offers/components/EditAttachments.vue";
import FileUploadComponent from "@/views/withdrawals/FileUploadComponent.vue";
import VueMultiselect from "vue-multiselect/src/Multiselect.vue";
import * as Yup from "yup";
import MaterialSwitch from "@/components/MaterialSwitch.vue";

export default {
  name: "OffersInfo",
  components: {
    MaterialSwitch,
    VueMultiselect,
    FileUploadComponent, EditAttachments,
    MaterialInput,
    MaterialButton,
    ValidationError,
  },
  mixins: [formMixin],
  props: {
    offerId: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      schema: Yup.object().shape({
        email: Yup.string().email("Email has to be a valid email address").required("Email is a required input"),
      }),
      offer: {},
      activeClass: "js-active position-relative",
      landingPageFileToUpload: null,
      bannerFileToUpload: null,
      pricePlaceholder: 'Price (in local currency)',
      priceDisabled: false,
      in_house: 1,
      metricTypeOptions: [
        'CPA',
        'CPL',
        'SS',
      ],
      privacyLevels: [
        'public',
        'private',
        'semiprivate',
      ],
      offerTypes: [
        'FP',
        'Free',
        'Hybrid',
      ],
      geoOptions: ['PL', 'RO', 'CZ', 'BG', 'DE'],
      azurelab_commission: 0
    }
  },
  watch: {
    offer: {

      handler: function (value) {
        if(value.offer_type === 'Free') {
          this.priceDisabled = true;
          this.pricePlaceholder = this.getPriceLabelDependingOnGeo(value.geo);
          this.offer.price = null;
        } else {
          this.priceDisabled = false;
          this.pricePlaceholder = 'Price (in local currency)';
        }
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters({
      productOptions: 'products/getProductNamesWithIdsData',
    }),
    bannerFiles() {
      return this.offer?.files?.filter(el => {
        return el.collection_name === "banner-files"
      });
    },
    landingPageFiles() {
      return this.offer?.files?.filter(el => {
        return el.collection_name === "landing-page-files"
      });
    },
  },
  async created() {
    await store.dispatch("products/getProductNamesWithIds");
  },
  async mounted() {
    if ('' !== this.offerId) {
      await store.dispatch("offers/getOffer", this.offerId);
      this.offer = this.$store.getters["offers/getOffer"];
      if (this.offer) {
        this.offer.price = this.offer.price_amount / 100.0;
        this.offer.payout = this.offer.payout_amount / 100.0;
      }
    }
  },
  methods: {
    getPriceLabelDependingOnGeo(geo) {
      if (geo === 'DE') {
        return 'Gratisprobe';
      }
      if (geo === 'PL') {
        return 'Darmowa Próbka';
      }
      if (geo === 'CZ') {
        return 'Vzorka zdarma';
      }
      if (geo === 'RO') {
        return 'Mostră gratuită';
      }
      if (geo === 'BG') {
        return 'безплатна проба';
      }

      return 'Free Sample';
    },
    updateBannerFiles(value) {
      this.offer.bannerFileToUpload = value
    },
    updateLandingPageFiles(value) {
      this.offer.landingPageFileToUpload = value
    },

    handleSave() {
      console.log("this.offer", this.offer)
      this.$emit('update:handleSave', this.offer);
    },
  },
};

</script>
