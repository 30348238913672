<template>
  <div v-if="role === 'admin' || role === 'affiliate_manager'"
       class="dataTable-search search-block col-12 col-md-6 col-lg-2">
    <VueMultiselect
        v-model="searchAdvertiserId"
        :options="userOptions ?? []"
        deselect-label="-"
        label="name"
        placeholder="Advertiser"
        select-label='+'
        track-by="id"
    >
    </VueMultiselect>
  </div>
</template>

<script>
import VueMultiselect from "vue-multiselect/src/Multiselect.vue";
import {mapGetters} from "vuex";

export default {
  components: {
    VueMultiselect,
  },

  data() {
    return {
      searchAdvertiserId: null,
    };
  },

  computed: {
    ...mapGetters({
      userOptions: "users/getAdvertisersIds",
      userProfile: "profile/getUserProfile",
    }),
    role() {
      return this.userProfile?.roles[0]?.name;
    },
  },
  watch: {
    searchAdvertiserId: {
      handler: "updateSearchAdvertiserId"
    },
    userProfile(newVal) {
      if (newVal && (newVal?.roles[0].name === 'admin' || newVal?.roles[0].name === 'affiliate_manager')) {
        this.$store.dispatch("users/getAdvertiserIds");
      }
    }
  },
  created() {
    this.$store.dispatch("users/getAdvertiserIds");
  },
  methods: {
    // Method to update searchAdvertiserId and emit its value to the parent
    updateSearchAdvertiserId(value) {
      var filter = {
        decision: String,
        filters: []
      }
      if (null === value) {
        filter.decision = 'delete';
        filter.filters.push({name: 'user_id'})
      } else {
        filter.decision = 'add'
        filter.filters.push({name: 'user_id', value: value.id})
      }

      this.$emit('update:updateFilters', filter);
    }
  },
};
</script>

<style scoped>
</style>
