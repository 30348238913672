import {createRouter, createWebHistory} from "vue-router";
import Overview from "../views/pages/profile/Overview.vue";
import Projects from "../views/pages/profile/Projects.vue";
import Timeline from "../views/pages/projects/Timeline.vue";
import Pricing from "../views/pages/Pricing.vue";
import RTL from "../views/pages/Rtl.vue";
import Charts from "../views/pages/Charts.vue";
import Notifications from "../views/pages/Notifications.vue";
import Kanban from "../views/applications/Kanban.vue";
import Wizard from "../views/applications/wizard/Wizard.vue";
import DataTables from "../views/applications/DataTables.vue";
import Calendar from "../views/applications/Calendar.vue";
import NewProduct from "../views/template/ecommerce/products/NewProduct.vue";
import EditProduct from "../views/template/ecommerce/products/EditProduct.vue";
import ProductPage from "../views/template/ecommerce/products/ProductPage.vue";
import OrderDetails from "../views/template/ecommerce/orders/OrderDetails";
import OrderList from "../views/template/ecommerce/orders/OrderList";
import NewUser from "../views/pages/users/NewUser.vue";
import Settings from "../views/account/Settings.vue";
import Billing from "../views/account/Billing.vue";
import Invoice from "../views/account/Invoice.vue";
import Widgets from "../views/pages/Widgets.vue";
import Basic from "../views/auth/signin/Basic.vue";
import Cover from "../views/auth/signin/Cover.vue";
import Illustration from "../views/auth/signin/Illustration.vue";
import ResetCover from "../views/auth/reset/Cover.vue";
import SignupCover from "../views/auth/signup/Cover.vue";
import Login from "../views/Login.vue";
import Signup from "../views/Signup.vue";
import PasswordForgot from "../views/PasswordForgot.vue";
import PasswordReset from "../views/PasswordReset.vue";
import WithdrawalsList from "../views/withdrawals/WithdrawalsList.vue";
import store from "@/store";
import WebsDashboard from "@/views/dashboards/WebsDashboard/WebsDashboard.vue";
import Home from "@/views/Home.vue";
import Profile from "@/views/pages/users/components/Profile.vue";
import AffiliateProfile from "@/views/Affiliate/profile/AffiliateProfile.vue";
import AffiliateCampaignsDashboard from "@/views/dashboards/CampaignsDashboard/WebCampaignsDashboard.vue";
import WebCampaignsDashboard from "@/views/dashboards/CampaignsDashboard/WebCampaignsDashboard.vue";
import AddWithdrawal from "@/views/withdrawals/AddWithdrawal.vue";
import LeadsList from "@/views/leads/LeadsList.vue";
import CampaignsList from "@/views/campaigns/CampaignsList.vue";
import OffersList from "@/views/offers/OffersList.vue";
import OfferPage from "@/views/offers/OfferPage.vue";
import AddOffer from "@/views/offers/AddOffer.vue";
import UsersList from "@/views/users/UsersList.vue";
import AcceptCampaign from "@/views/campaigns/AcceptCampaign.vue";
import RequestAccessForOffer from "@/views/campaigns/RequestAccessForOffer.vue";
import EditOffer from "@/views/offers/EditOffer.vue";
import CampaignLinkGenerator from "@/views/offers/components/CampaignLinkGenerator.vue";
import OfferLinkGenerator from "@/views/offers/components/OfferLinkGenerator.vue";
import ShareOffer from "@/views/offers/ShareOffer.vue";
import CreateCustomPayout from "@/views/offers/CreateCustomPayout.vue";
import EditUser from "@/views/users/edit-user/EditUser.vue";
import AddUser from "@/views/users/add-user/AddUser.vue";
import GlobalPostbackUrlGenerator from "@/components/GlobalPostbackUrlGenerator.vue";
import CampaignPostbackUrlGenerator from "@/components/CampaignPostbackUrlGenerator.vue";
import FAQ from "@/views/FAQ.vue";
import CustomPayoutsList from "@/views/customPayouts/CustomPayoutsList.vue";
import EditCampaign from "@/views/campaigns/EditCampaign.vue";
import CreateCampaignForUser from "@/views/campaigns/CreateCampaignForUser.vue";

const routes = [
    {
        path: "/faq",
        name: "FAQ",
        component: FAQ,
    },
    {
        path: "/",
        name: "Default",
        component: Home,
    },

    {
        path: "/user-profile",
        name: "User Profile",
        component: Profile,
    },
    {
        path: "/webs-dashboard",
        name: "Webs Dashboard",
        component: WebsDashboard,
        props: true,
        meta: {
            authRequired: true,
            permission: ['admin', 'affiliate_manager', 'partner', 'advertiser']
        }
    },
    {
        path: "/campaigns-dashboard",
        name: "Campaigns Dashboard",
        component: AffiliateCampaignsDashboard,
        meta: {
            authRequired: true,
        }
    },

    // users
    {
        path: "/users",
        name: "Users List",
        component: UsersList,
        props: true,
        meta: {
            authRequired: true,
            permission: ['affiliate_manager', 'admin', 'partner']
        }
    },
    {
        path: "/users/add-user",
        name: "Add User",
        component: AddUser,
        meta: {
            authRequired: true,
            // permission: ['affiliate_manager', 'admin', 'partner']
        }
    },

    // campaigns
    {
        path: "/campaigns",
        name: "Campaigns List",
        component: CampaignsList,
        props: true,
        meta: {
            authRequired: true,
        }
    },
    {
        path: "/custom-payouts",
        name: "Custom Payout List",
        component: CustomPayoutsList,
        props: true,
        meta: {
            authRequired: true,
            role: ['admin', 'affiliate_manager']
        }
    },

    {
        path: "/campaigns/accept-campaign",
        name: "Accept Campaign",
        component: AcceptCampaign,
        props: true,
        meta: {
            authRequired: true,
            role: ['admin', 'affiliate_manager']
        }
    },
    {
        path: "/offers/link-generator/:offerId",
        name: "Offer Link Generator",
        component: OfferLinkGenerator,
        props: true,
        meta: {
            authRequired: true,
        }
    },
    {
        path: "/campaigns/create-for-user/:offerId",
        name: "Create Campaign for User",
        component: CreateCampaignForUser,
        props: true,
        meta: {
            authRequired: true,
            role: ['admin', 'affiliate_manager']
        }
    },
    {
        path: "/campaigns/link-generator/:campaignId",
        name: "Campaign Link Generator",
        component: CampaignLinkGenerator,
        props: true,
        meta: {
            authRequired: true,
        }
    },

    //withdrawals
    {
        path: "/withdrawals-list",
        name: "Withdrawals List",
        component: WithdrawalsList,
        meta: {
            authRequired: true,
        }
    },
    {
        path: "/generate-global-postback-url/:userId",
        name: "Global Postback Url Generator",
        component: GlobalPostbackUrlGenerator,
        props: true,
        meta: {
            authRequired: true,
        }
    },
    {
        path: "/generate-campaign-postback-url/:campaignId",
        name: "Campaign Postback Url Generator",
        component: CampaignPostbackUrlGenerator,
        props: true,
        meta: {
            authRequired: true,
        }
    },
    {
        path: "/affiliate/add-withdrawal",
        name: "Affiliate Add Withdrawal",
        component: AddWithdrawal,
        meta: {
            authRequired: true,
            permission: ['affiliate']
        }
    },

    //leads
    {
        path: "/leads",
        name: "Leads List",
        component: LeadsList,
        props: true,
        meta: {
            authRequired: true,
        }
    },
    {
        path: "/affiliate/profile",
        name: "Affiliate Profile",
        component: AffiliateProfile,
    },
    {
        path: "/pages/profile/overview",
        name: "Profile Overview",
        component: Overview,
    },
    {
        path: "/pages/profile/projects",
        name: "All Projects",
        component: Projects,
    },
    {
        path: "/pages/projects/timeline",
        name: "Timeline",
        component: Timeline,
    },
    {
        path: "/pages/pricing-page",
        name: "Pricing Page",
        component: Pricing,
    },
    {
        path: "/pages/rtl-page",
        name: "RTL",
        component: RTL,
    },
    {
        path: "/pages/charts",
        name: "Charts",
        component: Charts,
    },
    {
        path: "/pages/widgets",
        name: "Widgets",
        component: Widgets,
    },
    {
        path: "/pages/notifications",
        name: "Notifications",
        component: Notifications,
    },
    {
        path: "/applications/kanban",
        name: "Kanban",
        component: Kanban,
    },
    {
        path: "/applications/wizard",
        name: "Wizard",
        component: Wizard,
    },
    {
        path: "/applications/data-tables",
        name: "Data Tables",
        component: DataTables,
    },
    {
        path: "/applications/calendar",
        name: "Calendar",
        component: Calendar,
    },
    {
        path: "/ecommerce/products/new-product",
        name: "New Product",
        component: NewProduct,
    },
    {
        path: "/ecommerce/products/edit-product",
        name: "Edit Product",
        component: EditProduct,
    },
    {
        path: "/ecommerce/products/product-page",
        name: "Product Page",
        component: ProductPage,
    },
    {
        path: "/ecommerce/Orders/order-details",
        name: "Order Details",
        component: OrderDetails,
    },
    {
        path: "/ecommerce/Orders/order-list",
        name: "Order List",
        component: OrderList,
    },
    {
        path: "/pages/users/new-user",
        name: "New User",
        component: NewUser,
    },
    {
        path: "/pages/account/settings",
        name: "Settings",
        component: Settings,
    },
    {
        path: "/pages/account/billing",
        name: "Billing",
        component: Billing,
    },
    {
        path: "/pages/account/invoice",
        name: "Invoice",
        component: Invoice,
    },
    {
        path: "/authentication/signin/basic",
        name: "Signin Basic",
        component: Basic,
    },
    {
        path: "/authentication/signin/cover",
        name: "Signin Cover",
        component: Cover,
    },
    {
        path: "/authentication/signin/illustration",
        name: "Signin Illustration",
        component: Illustration,
    },
    {
        path: "/authentication/reset/cover",
        name: "Reset Cover",
        component: ResetCover,
    },
    {
        path: "/authentication/signup/cover",
        name: "Signup Cover",
        component: SignupCover,
    },
    {
        path: "/login",
        name: "Login",
        component: Login,
        meta: {
            authRequired: false,
            permission: null
        }
    },
    {
        path: "/signup",
        name: "Signup",
        component: Signup,
        meta: {
            authRequired: false,
            permission: null
        }
    },
    {
        path: "/password-forgot",
        name: "PasswordForgot",
        component: PasswordForgot,
        meta: {
            authRequired: false,
            permission: null
        }
    },
    {
        path: "/password-reset",
        name: "PasswordReset",
        component: PasswordReset,
        meta: {
            authRequired: false,
            permission: null
        }
    },
    {
        path: "/request-access",
        name: "Request Access",
        component: RequestAccessForOffer,
        props: true,
        meta: {
            authRequired: true,
        }
    },
    {
        path: "/offers-list",
        name: "Offers List",
        component: OffersList,
        meta: {
            authRequired: true,

        }
    },
    {
        path: "/offer-page/:offerId",
        name: "Offer Page",
        component: OfferPage,
        props: true,
        meta: {
            authRequired: true,
        }
    },
    {
        path: "/web/:webId",
        name: "Web Campaigns Dashboard",
        component: WebCampaignsDashboard,
        props: true,
        meta: {
            authRequired: true,
        }
    },
    {
        path: "/edit-campaign/:campaignId",
        name: "Edit Campaign",
        component: EditCampaign,
        props: true,
        meta: {
            authRequired: true,
            permission: ['admin', 'affiliate_manager']
        }
    },

    {
        path: "/edit-offer/:offerId",
        name: "Edit Offer",
        component: EditOffer,
        props: true,
        meta: {
            authRequired: true,
            permission: ['admin']
        }
    },
    {
        path: "/add-offer",
        name: "Add Offer",
        component: AddOffer,
        meta: {
            authRequired: true,
            permission: ['admin', 'affiliate_manager']
        }
    },
    {
        path: "/share-offer",
        name: "Share Offer",
        component: ShareOffer,
        meta: {
            authRequired: true,
            permission: ['admin', 'affiliate_manager']
        }
    },
    {
        path: "/set-custom-payout",
        name: "Set Custom Payout",
        component: CreateCustomPayout,
        meta: {
            authRequired: true,
            permission: ['admin', 'affiliate_manager']
        }
    },
    {
        path: "/add-request",
        name: "Add Withdrawal",
        component: AddWithdrawal,
        meta: {
            authRequired: true,
        }
    },
    {
        path: "/edit-user/:userId",
        name: "Edit User",
        component: EditUser,
        props: true,
        meta: {
            authRequired: true,
            permission: ['admin']
        }
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    linkActiveClass: "active",
});

//eslint-disable-next-line no-unused-vars
router.beforeEach(async (to, from, next) => {
    const {authRequired, permission} = to.meta;

    const isLoggedIn = store.getters['auth/isLoggedIn'];

    if (to.name === 'Default' && !isLoggedIn) {
        // redirect to public/LP/index.html
        document.location.href = "/welcome.html";
    }

    if (authRequired) {

        //if trying to access a page that requires authentication but not logged in, redirect to login page
        if (!isLoggedIn) {
            return next({name: 'Login', query: {returnUrl: to.path}});
        }

        //if trying to access a page that requires permission, redirect to dashboard
        if (permission) {
            try {
                await store.dispatch('profile/getProfile')
                const userPermission = (store.getters['profile/getUserProfile']).roles[0].name;
                if (!permission.includes(userPermission)) {
                    return next({name: 'Default', query: {returnUrl: to.path}});
                }
            } catch (error) {
                try {
                    store.dispatch('auth/logout');
                } finally {
                    // eslint-disable-next-line no-unsafe-finally
                    return next({name: "Login"});
                }
            }
        }

    }
    next();
})

export default router;
